import dayjs from "dayjs";
import * as XLSX from 'xlsx';

export const getBase64 = (file) =>
new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const formatNumber = (number, multiplier) => {
    let result;
    if (number === null || number === undefined) {
      result = ''
    }
    if (number === 0) {
      result = 0
    }
    try {
      result = number.toLocaleString('en-US', {
        minimumFractionDigits: multiplier ?? 0,
        maximumFractionDigits: multiplier ?? 0,
      });
    } catch (error) {
      result = ''
    }
    return result;
  }

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const filterOption = (input, option) =>
(option?.label ?? '').toLowerCase().includes(input.toLowerCase());



export const getDaysInMonth = (month, year) => {
    const daysInMonth = dayjs(`${year}-${month}`).daysInMonth();
    const daysArray = [];
    const firstDayOfWeek = dayjs(`${year}-${month}-01`).day();
    for (let day = 1; day <= daysInMonth; day++) {
        daysArray.push(dayjs(`${year}-${month}-${day}`));
    }
    for (let i = 1; i <= firstDayOfWeek; i++) {
      daysArray.unshift(-1);
  }
    return daysArray;
};

export const exportToExcel = (data, fileName) => {
  const columnMapping = [
      { header: "STT", key: "order" },
      { header: "Mã vận đơn", key: "billCode" },
      { header: "Thời gian quét", key: "checkIn" },
      { header: "Người quét", key: "fullname" },
  ];
  // Tạo tiêu đề từ ánh xạ
  const headers = columnMapping.map(col => col.header);

  // Chuyển đổi dữ liệu theo thứ tự ánh xạ
  const formattedData = data.map((el, index) => ({
      order: index + 1,
      ...el,
  })).map(item =>
      columnMapping.map(col => item[col.key])
  );

  // Thêm tiêu đề vào dữ liệu
  const worksheetData = [headers, ...formattedData];

  // Tạo worksheet từ dữ liệu đã định dạng
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Đặt độ rộng cột
  worksheet['!cols'] = columnMapping.map((col, index) => ({ wpx: index === 0 ? 50 : 150 }));

  // Tạo workbook và thêm worksheet
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Xuất file Excel
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};