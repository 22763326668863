import {
    LogoutOutlined,
    FileSearchOutlined,
    TeamOutlined,
    ProjectOutlined,
    CheckCircleOutlined,
    ReloadOutlined
} from '@ant-design/icons';

export const DATE_FORMAT = {
    DDMMYYYY_FORMAT: "DD/MM/YYYY",
    YYYYMMDD_FORMAT: "YYYY-MM-DD",
    DDMM_FORMAT: "DD/MM",
    DD_MM_YYYY_FORMAT: "DD-MM-YYYY"
}

export const TYPE_MODAL = {
    ADD: "ADD",
    EDIT: "EDIT",
    DEL: "DEL",
    APPROVE: "APPROVE"
}

export const LIST_MENU_SIDE_BAR = [
    {
        key: 'check-code',
        path: "/check-code",
        label: 'Quét mã vận đơn',
        icon: <CheckCircleOutlined />,
        permission: [1, 2]
    },
    // {
    //     key: 'chi-nhanh',
    //     path: "/chi-nhanh",
    //     label: 'Chi nhánh',
    //     icon: <ClusterOutlined />,
    //     permission: [1, 0]
    // },
    {
        key: 'quan-ly-tai-khoan',
        path: "/quan-ly-tai-khoan",
        label: 'Quản lý tài khoản',
        icon: <TeamOutlined />,
        permission: [1]
    },
    {
        label: 'Báo cáo',
        icon: <ProjectOutlined />,
        children: [
            {
                key: 'bao-cao-mvd-trung',
                path: "/bao-cao-mvd-trung",
                label: 'Báo cáo mã vận đơn trùng',
                icon: <FileSearchOutlined />,
                permission: [1, 2]
            },
            {
                key: 'bao-cao-tong-hop',
                path: "/bao-cao-tong-hop",
                label: 'Báo cáo tổng hợp',
                icon: <FileSearchOutlined />,
                permission: [1, 2]
            }
        ],
        permission: [1, 2]
    },
    {
        key: 'thay-doi-mat-khau',
        path: "/thay-doi-mat-khau",
        label: 'Đổi mật khẩu',
        icon: <ReloadOutlined />,
        permission: [1, 2]
    },
    {
        key: 'dang-xuat',
        path: "/dang-xuat",
        label: 'Đăng xuất',
        icon: <LogoutOutlined />,
        permission: [1, 2]
    },

];

export const requiredItem = [
    {
        required: true,
        message: 'Vui lòng không để trống'
    }
];



export const SHEET_APP_ID_DEFAULT = "AKfycbxDMiy4rGwAfuK6kk12AG_JcqXZFWP3rDS4vXNilVLt4-dDRBhrL4Wruye8imyI4R2v7A";