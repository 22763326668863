import { Button, Col, Form, Input, Row, Space, theme, Table, message, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import LoadingBase from '../../components/LoadingBase';
import '../../css/listbook-page.css';
import { getListAccount, getReportBillDuplicate } from '../../services/apis';
import FooterTable from '../../components/FooterTable';
import { consoleLogUtil } from '../../utils/consoleLogUtil';
import dayjs from 'dayjs';

const ReportBillDuplicatePage = () => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const [totalPage, setTotalPage] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataUser, setDataUser] = useState([]);
    const { RangePicker } = DatePicker;
    const columns = [
        {
            title: 'STT',
            dataIndex: 'index',
            width: 45,
            key: 'index',
            align: 'center',
            render: (text, record, index) => (index + 1) + 50 * (currentPage - 1),
        },
        {
            title: 'Mã vận đơn',
            dataIndex: 'code',
            key: 'code',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Thời gian quét lại',
            dataIndex: 'check_in',
            key: 'check_in',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Quét lần đầu',
            dataIndex: 'fisrtCheck',
            key: 'fisrtCheck',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        },
        {
            title: 'Nhân viên kiểm tra',
            dataIndex: 'checkByName',
            key: 'checkByName',
            render: (text) => <div style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 12 }}>{text}</div>
        }

    ];

    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const handleNext = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page + 1);
        getDataReport(page + 1);
    }

    const handlePrev = () => {
        if (loading) {
            return;
        }
        const page = currentPage;
        setCurrentPage(page - 1);
        getDataReport(page - 1);
    }
    const handleOnSelect = (value) => {
        setCurrentPage(value);
        getDataReport(value)
    }
    const handleChangeKeyword = (e) => {
        consoleLogUtil("handleChangeKeyword", e.target.value);
        
    }
    const getDataReport = async (page) => {
        const params = {
            keyword: form.getFieldValue("name") ?? null,
            limit: 50,
            currentPage: page,
            startTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[0])?.startOf('day').format("YYYY-MM-DD HH:mm:ss") : dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
            endTime: form.getFieldValue("date") ? dayjs(form.getFieldValue("date")?.[1])?.endOf('day')?.format("YYYY-MM-DD HH:mm:ss") : dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss"),
            userId: form.getFieldValue("staff") ?? null
        }
        setLoading(true);
        const res = await getReportBillDuplicate(params);
        setLoading(false);
        if (res && res.status === 1) {
            setData([...res?.data?.data]);
            setTotalPage(res?.data?.totalPages);
        } else {
            setData([]);
            setTotalPage(0);
            message.warning(res?.msg ?? "Không có dữ liệu");
        }
    }


    const getDataUsers = async () => {
        const params = {
            keyword: '',
        }
        setLoading(true);
        const res = await getListAccount(params);
        setLoading(false);
        if (res && res?.status === 1) {
            setDataUser([...res?.data ?? []]);
        } else {
            setDataUser([]);
        }
    }

    const onFinish = async (data) => {
        setCurrentPage(1);
        getDataReport(1);
    };
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        getDataUsers();
        getDataReport(1);
    }, [])

    return (
        <div className='container-content'>
            <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name={'name'}
                        >
                            <Input placeholder={'Mã vận đơn'} onChange={handleChangeKeyword}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="staff"
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder={'Chọn nhân viên'}
                                style={{ width: '100%', height: 40 }}
                                defaultActiveFirstOption={false}
                                filterOption={filterOption}
                                notFoundContent={null}
                                options={dataUser.map(item => ({ label: item.fullname, value: item.id }))}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name={'date'}
                        >
                            <RangePicker 
                                placeholder={["Bắt đầu", "Kết thúc"]}
                                format={"DD-MM-YYYY"}
                                showTime={false}
                                style={{height: 40}}
                                allowClear={false}
                                defaultValue={[dayjs(), dayjs()]}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Space size="small">
                            <Button type="primary" htmlType="submit">
                                Tìm kiếm
                            </Button>

                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                className='table-book'
                columns={columns}
                dataSource={data}
                pagination={false}
                rowKey={(record) => record?.id}
            />
            <FooterTable
                totalRecord={data?.length}
                totalPage={totalPage}
                currentPage={currentPage}
                handleNext={handleNext}
                handlePrev={handlePrev}
                onSelect={handleOnSelect}
            >
            </FooterTable>
            <LoadingBase loading={loading} />
        </div>
    )
}
export default React.memo(ReportBillDuplicatePage);